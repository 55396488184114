<template>
  <ProgressBar ref="progress-bar" />
  <main class="d-flex align-center justify-center flex-column" v-if="questions.length > 0">
    <div class="test-question-number d-flex align-center justify-center mb-10">{{ currentQuestion + 1 }}</div>
    <h2 class="text-center">{{ questions[currentQuestion].text }}</h2>
    <div class="test-answers-container d-flex justify-center flex-column" v-if="questions[currentQuestion]">
      <div v-for="(answer, index) in questions[currentQuestion].answers" :key="index" class="test-answer-container">
        <div class="d-flex align-start justify-start">
          <label :for="index">
            <input type="radio" :id="index" name="answer" :value="answer.id" v-model="currentAnswers[currentQuestion]" @change="setAnswer($event, answer.id)">
            <div class="checkbox"></div>
            <div class="checkmark"></div>
            <span>{{ answer.text }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="button-container d-flex justify-space-between align-items-center flex-wrap">
      <v-btn v-if="currentQuestion" class="d-flex align-center justify-center"  @click="previousQuestion"><v-icon icon="mdi-arrow-left"/>{{ $store.getters.langData.test.previousQuestion }}</v-btn>
      <div v-else></div>
      <v-btn v-if="questions.length-1 > currentQuestion" class="d-flex align-center justify-center" @click="nextQuestion"  :disabled="currentAnswers[currentQuestion].length === 0">{{ $store.getters.langData.test.nextQuestion }}<v-icon icon="mdi-arrow-right"/></v-btn>
      <v-btn v-else class="d-flex align-center justify-center" @click="finishTest" :disabled="currentAnswers[currentQuestion].length === 0">{{ $store.getters.langData.test.finishTest }}</v-btn>
    </div>
  </main>
</template>

<script>
import config from "@/helpers/api";
import ProgressBar from "@/components/ProgressBar.vue";
export default {
  name: 'TestView',
  components: {ProgressBar},
  data() {
    return {
      currentQuestion: 0,
      questions: [],
      currentAnswers: [],
      answers: []
    }
  },
  async mounted() {
    await fetch(config.baseURL + '/current_test', {
      headers: {
        ...config.headers,
      }
    }).then(async (response) => {
      const data = await response.json()
      this.questions = data.data.questions
      this.questions.map(() => {
        this.currentAnswers.push([])
      })
    })

    this.setProgress()

  },
  methods: {
    setAnswer (e, id) {
      if(e.target.checked) {
          this.answers.push(id)
      } else {
        this.answers.splice(this.answers.indexOf(id), 1)
      }
    },
    setProgress() {
      this.$refs["progress-bar"].setProgress(((this.currentQuestion + 1) * 100) / this.questions.length)
    },
    nextQuestion () {
      if(this.currentAnswers.length === 0) return
      if(this.questions.length - 1 > this.currentQuestion) {
        this.currentQuestion += 1
        this.setProgress()
      }
    },
    previousQuestion () {
      if(0 < this.currentQuestion) {
        this.currentQuestion -= 1
        this.setProgress()
      }
    },
    async finishTest () {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      await fetch(config.baseURL + '/save_user_test', {
        method: 'POST',
        headers: {
          ...config.headers,
        },
        body: JSON.stringify({ test_answers: this.answers, "g-recaptcha-response": token, }),
      }).then(async (response) => {
        const data = await response.json()
        this.$router.push('/loading/' + data.data.key)
      })
    }
  }
}
</script>

<style scoped>
main {
  padding-inline: 20px;
}

.test-question-number {
  width: 71px;
  height: 71px;
  background-color: #FFBE0B;
  color: white;
  border-radius: 50%;
  font-size: 24px;
  margin-top: 10vh;
}

.test-answers-container {
  max-width: 600px;
  width: 100%;
}

.test-answer-container {
  position: relative;
  width: 100%;
  z-index: 10;
  margin-top: 30px;
}

.test-answer-container:first-child {
  margin-top: 50px;
}


.test-answer-container > div {
  background: rgb(255, 255, 255);
  border: 2px solid #C9C9C9;
  border-radius: 8px;
  padding: 20px 20px 20px 45px;
  gap: 10px;
}

.test-answer-container::before {
  content: '';
  position: absolute;
  width: 98%;
  height: 95%;
  left: -10px;
  top: -10px;
  border-radius: 10px;
  z-index: -2;
  background: rgba(255, 190, 11, 0.2);
}

input[type="radio"] {
  margin-right: 10px;
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.checkbox {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  cursor: pointer;
}

.checkmark {
  content: '';
  width: 8px;
  height: 15px;
  border-right: 3px solid #FFBE0B;
  border-bottom: 3px solid #FFBE0B;
  transform: rotate(45deg);
  left: 26px;
  position: absolute;
  display: none;
}

.test-answer-container > div > label:has(input[type="radio"]:checked) > .checkmark {
  display: initial;
}

.test-answer-container:has(input[type="radio"]:checked)::before {
  background: rgba(255, 190, 11, 0.6);
}

.test-answer-container > div:has(input[type="radio"]:checked) {
  background: rgba(255, 255, 255, 0.980392);
  border: 2px solid #FFBE0B;
  border-radius: 8px;
}

button {
  padding: 10px;
  background-color: #FFBE0B;
  border-radius: 6px;
  gap: 20px;
  font-weight: bold;
}

.button-container {
  max-width: 600px;
  width: 100%;
  margin-top: 20px;
}

label {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
}

@media only screen and (max-width: 600px) {
  .button-container {
    align-items: stretch;
    justify-content: center !important;
    flex-direction: column-reverse;
    gap: 10px;
  }
}
</style>