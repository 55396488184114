<template>
  <div class="container">
    <div class="image-container">
      <MainHeader />
      <v-main>
        <div class="content-wrapper">
          <slot />
        </div>
      </v-main>
    </div>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
export default {
  components: {
    MainFooter,
    MainHeader,
  },
  mounted() {
    this.loadGoogleTranslateScript()
  },
  methods: {
    loadGoogleTranslateScript() {
      const script = document.createElement('script')
      script.id = 'google-language'
      script.src =
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      script.async = true
      document.head.appendChild(script)
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
              pageLanguage: 'auto',
              includedLanguages: 'lv,en,et,mk',
              layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT
            },
            'app'
        )
      }
    },
  }
}
</script>

<style scoped>
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-container {
  height: 100%;
  background-image: url("/src/assets/background-image.png");
  background-position: center;
  background-size: cover;
}

main {
  position: relative;
  width: 100vw;
  margin: auto auto 50px;
}

.content-wrapper {
  height: 100%;
  margin-top: 30px;
}
</style>
