<template>
  <header>
    <div class="header-container">
      <div>
        <v-img :src="logo" width="100px" class="logo"/>
      </div>
      <div class="ga-10 pr-10 d-flex desktop">
        <v-img :src="partner1" width="70px" class="logo" :class="{'order-0': currentLanguage === 'lv', 'order-1':  currentLanguage !== 'lv'}"/>
        <v-img :src="partner2" width="70px" class="logo" :class="{'order-0': currentLanguage === 'est', 'order-2':  currentLanguage !== 'et'}"/>
        <v-img :src="partner3" width="70px" class="logo" :class="{'order-0': currentLanguage === 'mk', 'order-3':  currentLanguage !== 'mk'}"/>
        <v-img :src="erasmus" width="250px" class="logo order-4"/>
      </div>
      <v-menu
          transition="slide-x-reverse-transition"
          location="start"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              color="#F4BE4F"
              icon="mdi-translate"
              variant="flat"
              v-bind="props"
          >
          </v-btn>
        </template>
        <div class="country-list-wrapper">
          <v-list
              density="compact"
              class="d-flex flex-row justify-space-evenly country-list country-list-container"
          >
            <v-list-item v-for="(language, index) in languages" :key="index" class="pa-0 ma-0">
              <a v-if="currentLanguage === language.code" @click="changeLanguage(language.code)" class="cursor-pointer">
                <v-img :src="language.image" class="country-image"  />
              </a>
              <a v-else-if="language?.site" :href="language.site" class="cursor-pointer">
                <v-img :src="language.image" class="country-image"  />
              </a>
              <a v-else>
                <v-img :src="language.image" class="country-image cursor-pointer" @click="changeLanguage(language.code)" />
              </a>
<!--              <a v-else :href="language.site">-->
<!--                <v-img :src="language.image" class="country-image"  />-->
<!--              </a>-->
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </div>
  </header>
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      logo: new URL("/src/assets/logo.svg", import.meta.url).href,
      partner1: new URL("/src/assets/rvt.png", import.meta.url).href,
      partner2: new URL("/src/assets/voco.png", import.meta.url).href,
      partner3: new URL("/src/assets/parentija.png", import.meta.url).href,
      erasmus: new URL("/src/assets/erasmus.png", import.meta.url).href,
      flag_eng: new URL('/src/assets/uk.png', import.meta.url).href,
      currentLanguage: process.env.VUE_APP_LANGUAGE_CODE,
      languages: [
        {
          code: 'lv',
          site: 'https://career.rvt.lv/',
          image: new URL('/src/assets/latvia.png', import.meta.url).href,
        },
        {
          code: 'et',
          site: 'https://est-career.rvt.lv/',
          image: new URL('/src/assets/estonia.png', import.meta.url).href,
        },
        {
          code: 'mk',
          site: 'https://mk-career.rvt.lv/',
          image: new URL('/src/assets/macedonia.png', import.meta.url).href,
        },
        {
          code: 'en',
          // site: 'https://mk-career.rvt.lv/',
          image: new URL('/src/assets/uk.png', import.meta.url).href,
        }
      ]
    }
  },
  methods: {
    changeLanguage(lang) {
      let googleTranslateComboBox = document.querySelector('.goog-te-combo')
      if (googleTranslateComboBox) {
        googleTranslateComboBox.value = lang
        if(lang !== this.currentLanguage) {
          window.location = `#googtrans(${process.env.VUE_APP_LANGUAGE_CODE}|${lang})`
          location.reload()
        } else {
          const domains = [window.location.origin, "rvt.lv"];

          // Clear the cookie for both domains
          domains.forEach(domain => {
            document.cookie = `googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
            document.cookie = `googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain};`; // Leading dot for subdomains
          });

          // Fallback: Clear without domain attribute
          document.cookie = "googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          location.href = location.origin;
        }
      }
    }
  }
}
</script>

<style scoped>
header {
  width: 85vw;
  margin: auto;
}
.header-container {
  padding: 3vh 3vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.country-image {
  border: 1px solid #3d3d3d;
  box-sizing: content-box;
  padding: 0;
  width: 50px;
}

.country-list {
  width: 100%;
  padding: 4px;
  border-radius: 9px;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
}

.country-list-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 20px;
  border-radius: 9px;
  -webkit-box-shadow: -10px -10px 0 0 rgba(255,190,11,0.3) !important;
  -moz-box-shadow: -10px -10px 0 0 rgba(255,190,11,0.3) !important;
  box-shadow: -10px -10px 0 0 rgba(255,190,11,0.3) !important;
}

.country-list-container {
  gap: 5px;
}

@media only screen and (max-width: 400px) {
  .country-list-wrapper {
    margin-right: -50px;
  }
}

@media only screen and (min-width: 1460px) {
  .desktop {
    display: flex !important;
  }
}

@media only screen and (max-width: 1460px) {
  .desktop {
    display: none !important;
  }
}
</style>
