<template>
  <div :id="id" class="section">
    <div class="container">
      <div class="section-left">
        <div class="rating-container">
          <div class="rating-columns">
            <div class="rating-column-container">
              <img class="rating-image" :class="{ 'image-animation': startAnimation }" :src="results[2].banner" alt="speciality image">
              <div class="rating-column bronze" :class="{ 'bronze-animation': startAnimation }">
                <p class="rating-column-text">{{ results[2].percentage }}%</p>
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  {{ results[2].title }}
                </v-tooltip>
              </div>
            </div>
            <div class="rating-column-container">
              <img class="rating-image" :class="{ 'image-animation': startAnimation }" :src="results[0].banner" alt="speciality image">
              <div class="rating-column gold" :class="{ 'gold-animation': startAnimation }">
                <p class="rating-column-text">{{ results[0].percentage }}%</p>
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  {{ results[0].title }}
                </v-tooltip>
              </div>
            </div>
            <div class="rating-column-container">
              <img class="rating-image" :class="{ 'image-animation': startAnimation }" :src="results[1].banner" alt="speciality image">
              <div class="rating-column silver" :class="{ 'silver-animation': startAnimation }">
                <p class="rating-column-text">{{ results[1].percentage }}%</p>
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  {{ results[1].title }}
                </v-tooltip>
              </div>
            </div>
          </div>
          <div class="rating-floor"/>
        </div>
        <AppleContainer class="apple-container desktop-container" :title="$store.getters.langData.result.saveResultsTitle">
          <form id="send-results" class="save-results-container" @submit.prevent="sendResults()">
            <p class="save-results-text">{{ $store.getters.langData.result.saveResultsText }}</p>
            <input v-model="email" class="save-results-input" type="email" :placeholder="$store.getters.langData.result.saveResultsPlaceholder">
            <v-btn class="text-white" color="#FFBE0B" type="submit" form="send-results">{{ $store.getters.langData.result.saveResultsButton }}</v-btn>
          </form>
        </AppleContainer>
      </div>
      <div class="section-right">
        <div class="speciality">
          <h1 class="speciality-title">{{ $store.getters.langData.result.overview }}</h1>
          <p class="speciality-description">{{ $store.getters.langData.result.overviewText }}</p>
        </div>
<!--        <div class="school">-->
<!--          <h1 class="school-title">{{ $store.getters.langData.result.SpecialistTitle }}</h1>-->
<!--          <p class="school-description">{{ $store.getters.langData.result.SpecialistSchool }}</p>-->
<!--          <p class="school-description">{{ $store.getters.langData.result.SpecialistName }}</p>-->
<!--          <p class="school-description">{{ $store.getters.langData.result.SpecialistEmail }}</p>-->
<!--          <p class="school-description">{{ $store.getters.langData.result.SpecialistAddress }}</p>-->
<!--        </div>-->
        <AppleContainer class="apple-container mobile-container" :title="$store.getters.langData.result.saveResultsTitle">
          <form class="save-results-container" @submit.prevent="sendResults()">
            <p class="save-results-text">{{ $store.getters.langData.result.saveResultsText }}</p>
            <input v-model="email" class="save-results-input" type="email" :placeholder="$store.getters.langData.result.saveResultsPlaceholder">
            <v-btn class="text-white" color="#FFBE0B" type="submit" form="send-results">{{ $store.getters.langData.result.saveResultsButton }}</v-btn>
          </form>
        </AppleContainer>
      </div>
    </div>
  </div>
</template>

<script>
import AppleContainer from "@/components/AppleContainer.vue";
import config from "@/helpers/api";

export default {
  name: 'ResultsView',
  components: {AppleContainer},
  props: ['id', 'results'],
  data() {
    return {
      startAnimation: false,
      email: '',
    }
  },
  methods: {
    startResultsAnimation() {
      this.startAnimation = true
    },
    async sendResults() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      await fetch(config.baseURL + '/send_result', {
        method: 'POST',
        headers: {
          ...config.headers,
        },
        body: JSON.stringify({
          'link': window.location.href,
          'email': this.email,
          'g-recaptcha-response': token,
        }),
      }).then(async (response) => {
        if(response.ok) {
          // TODO Get translated message from api
          alert("The mail was sent")
        } else {
          alert("Error was occurred")
        }
      })
    }
  }
}
</script>

<style scoped>
.section {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  gap: 25px;
}

.section-left {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 40%;
  .rating-container {
    border-radius: 16px;
    width: 100%;
    min-height: 400px;
    background-color: #FBFBFB;
    border: solid 1px #E6E6E6;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    padding-bottom: 25px;
    .rating-columns {
      display: flex;
      gap: 25px;
      height: 100%;
      .rating-column-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        gap: 15px;
        .rating-column {
          padding-top: 0px;
          width: 100%;
          height: 0px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          transition: 1s;
          box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
          .rating-column-text {
            color: white;
            font-size: 24px;
            user-select: none;
          }
        }
        .bronze {
          background-color: #CD7F32;
        }
        .gold {
          background-color: #FED152;
        }
        .silver {
          background-color: #D2D2D2;
        }
        .bronze-animation {
          animation: bronzeAnim 2s ease 0s 1 normal forwards;
        }
        .gold-animation {
          animation: goldAnim 2s ease 0s 1 normal forwards;
        }
        .silver-animation {
          animation: silverAnim 2s ease 0s 1 normal forwards;
        }
        .image-animation {
          animation: imageAnim 2s ease 0s 1 normal forwards;
        }
        .rating-image {
          opacity: 0;
          transition: 1s;
          width: 90px;
          height: 90px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .rating-floor {
      width: 80%;
      height: 12px;
      background-color: #FFBC00;
      border-radius: 10px;
      box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    }
  }
}

.apple-container {
  height: fit-content !important;
}

.desktop-container {
  display: block;
}
.mobile-container {
  display: none;
}

.save-results-container {
  padding: 18px 24px;
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .save-results-text{
    font-size: 16px;
    font-weight: lighter;
    text-align: start;
  }
  .save-results-input {
    width: 100%;
    transition: 0.2s;
    outline: solid 1px rgba(255, 255, 255, 0);
    border: solid 1px #C9C9C9;
    border-radius: 10px;
    padding: 8px 18px;
    box-shadow: -8px -6px 0px 0px rgba(255,242,206,1);
  }
  .save-results-input:focus {
    outline: solid 1px #FFBE0B;
    border: solid 1px #FFBE0B;
    box-shadow: -8px -6px 0px 0px rgba(255,216,109,1);
  }
}

.section-right {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 45%;
  text-align: left;
  .speciality {
    .speciality-title {
      font-size: 43px;
      color: #FFBE0B;
    }
    .speciality-description {
      font-size: 16px;
      color: #4E4E4E;
    }
  }
  .school {
    .school-title {
      font-size: 43px;
      color: #FFBE0B;
    }
    .school-description {
      font-size: 16px;
      color: #4E4E4E;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .rating-columns {
    gap: 15px !important;
  }
  .rating-column {
    width: 90% !important;
  }
  .rating-image {
    width: 64px !important;
    height: 64px !important;
  }
}

@media only screen and (max-width: 900px) {
  .desktop-container {
    display: none;
  }
  .mobile-container {
    display: block;
  }
  .container {
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 200px;
  }
  .section-left {
    width: 80%;
  }
  .rating-columns {
    gap: 15px !important;
  }
  .rating-column {
    width: 100% !important;
  }
  .rating-image {
    width: 90px !important;
    height: 90px !important;
  }
  .section-right {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .rating-columns {
    gap: 15px !important;
  }
  .rating-column {
    width: 90% !important;
  }
  .rating-image {
    width: 64px !important;
    height: 64px !important;
  }
}

@media only screen and (max-width: 380px) {
  .section-left,
  .section-right {
    width: 90%;
    .speciality {
      .speciality-title {
        font-size: 32px;
      }
    }
    .school {
      .school-title {
        font-size: 32px;
      }
    }
  }
  .rating-columns {
    gap: 5px !important;
  }
  .rating-floor {
    width: 95% !important;
  }
}

@media only screen and (max-width: 250px) {
  .rating-image {
    width: 48px !important;
    height: 48px !important;
  }
  .rating-column {
    width: 85% !important;
  }
  .rating-column-text {
    font-size: 16px !important;
  }
}

@keyframes bronzeAnim {
  0% {
    height: 0;
  }

  75%,
  100% {
    padding-top: 24px;
    height: 128px;
  }
}

@keyframes silverAnim {
  0% {
    height: 0;
  }

  75%,
  100% {
    padding-top: 24px;
    height: 186px;
  }
}

@keyframes goldAnim {
  0% {
    height: 0;
  }

  75%,
  100% {
    padding-top: 24px;
    height: 228px;
  }
}

@keyframes imageAnim {
  0%,
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

</style>