<template>
  <div class="page-container">
      <div class="side-bar-container">
        <img class="side-bar-logo" src="../assets/logo.svg" alt="logo">
        <div class="side-bar-navigation">
          <a href="#1" class="navigation">
            <div :class="[navigations[0].isActive ? 'line-active' : 'line-inactive']" />
            <div :class="[navigations[0].isActive ? 'dot-active' : 'dot-inactive']" />
            <p :class="[navigations[0].isActive ? 'text-active' : 'text-inactive']">{{ $store.getters.langData.result.speciality1 }}</p>
          </a>
          <a href="#2" class="navigation">
            <div :class="[navigations[1].isActive ? 'line-active' : 'line-inactive']" />
            <div :class="[navigations[1].isActive ? 'dot-active' : 'dot-inactive']" />
            <p :class="[navigations[1].isActive ? 'text-active' : 'text-inactive']">{{ $store.getters.langData.result.speciality2 }}</p>
          </a>
          <a href="#3" class="navigation">
            <div :class="[navigations[2].isActive ? 'line-active' : 'line-inactive']" />
            <div :class="[navigations[2].isActive ? 'dot-active' : 'dot-inactive']" />
            <p :class="[navigations[2].isActive ? 'text-active' : 'text-inactive']">{{ $store.getters.langData.result.speciality3 }}</p>
          </a>
          <a href="#4" class="navigation">
            <div :class="[navigations[3].isActive ? 'line-active' : 'line-inactive']" />
            <div :class="[navigations[3].isActive ? 'dot-active' : 'dot-inactive']" />
            <p :class="[navigations[3].isActive ? 'text-active' : 'text-inactive']">{{ $store.getters.langData.result.results }}</p>
          </a>
        </div>
      </div>
      <div id="scrollContainer" class="results-container" @scroll="setActiveItem">
        <template v-if="isFetchDone">
          <SpecialitySection ref="speciality0" id="1" :speciality="specialities[0]" :language="$store.getters.langData.result.speciality1" :is-first="true"/>
          <SpecialitySection ref="speciality1" id="2" :speciality="specialities[1]" :language="$store.getters.langData.result.speciality2"/>
          <SpecialitySection ref="speciality2" id="3" :speciality="specialities[2]" :language="$store.getters.langData.result.speciality3"/>
          <ResultsSection ref="results" id="4" :results="results"/>
        </template>
        <div v-else class="loading-container">
          <v-progress-circular
            indeterminate
            :size="128"
            :width="12"
            color="#FFBE0B"
          />
        </div>
      </div>
  </div>
</template>

<script>
import SpecialitySection from "@/components/SpecialitySection.vue";
import ResultsSection from "@/components/ResultsSection.vue";
import config from "@/helpers/api";

export default {
  name: 'ResultsView',
  components: {SpecialitySection, ResultsSection},
  data() {
    return {
      isFetchDone: false,
      specialities: null,
      results: [],
      navigations: [
        {
          index: 1,
          isActive: true
        },
        {
          index: 2,
          isActive: false
        },
        {
          index: 3,
          isActive: false
        },
        {
          index: 4,
          isActive: false
        },
      ],
      timer: null,
    }
  },
  created() {
    window.addEventListener('scroll', this.setActiveItem)
  },
  unmounted() {
    window.removeEventListener('scroll', this.setActiveItem)
  },
  mounted() {
    this.getResults()
  },
  methods: {
    async getResults() {
      await fetch(config.baseURL + '/test_result', {
        method: 'POST',
        headers: {
          ...config.headers,
        },
        body: JSON.stringify({ key: this.$route.params.key }),
      })
        .then(async (response) => {
          const data = await response.json()
          data.specialities.forEach((speciality) => {
            this.results.push({
              'banner': speciality.banner_url,
              'title': speciality.name,
              'percentage': speciality.percentage_answered,
            })
          })
          this.specialities = data.specialities
          this.isFetchDone = true
          let scrollContainer = document.getElementById('scrollContainer')
          scrollContainer.dispatchEvent(new Event('scroll'));
        })
        .catch(() => {
          this.$router.push('/')
        })
    },
    setActiveItem(e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.changeSection(e), 1);
    },
    changeSection(e) {
      Array.from(e.target.children).forEach((ele, index) => {
        try {
          if (Math.abs(ele.getBoundingClientRect().top - e.target.getBoundingClientRect().top) < 10) {
            this.navigations[index].isActive = true;
            if (index === 3) {
              this.$refs.results.startResultsAnimation()
            } else {
              this.$refs['speciality' + index].changeStatisticValues()
            }
          } else {
            this.navigations[index].isActive = false;
          }
        } catch (e) {
          //
        }
      });
    }
  }
}
</script>

<style scoped>
a, a:visited, a:hover, a:active {
  text-decoration: none;
}

.page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.side-bar-container {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  img {
    display: block;
    margin-inline: auto;
    padding-top: 32px;
  }
  .side-bar-navigation {
    margin-block: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    .navigation {
      display: flex;
      align-items: center;
      gap: 12px;
      height: 48px;
      .line-inactive {
        width: 18px;
        height: 100%;
        border-left: solid 3px #CACACA;
        transition: 0.3s;
      }
      .dot-inactive {
        border-radius: 100%;
        width: 14px;
        height: 14px;
        border: solid 2px #CACACA;
        transition: 0.3s;
      }
      .text-inactive {
        color: #616161;
        transition: 0.3s;
        font-weight: 600;
      }
      .line-active {
        width: 18px;
        height: 100%;
        border-left: solid 6px #FFBE0B;
        transition: 0.3s;
      }
      .dot-active {
        border-radius: 100%;
        width: 14px;
        height: 14px;
        border: solid 4px #FFBE0B;
        background-color: #FFBE0B;
        transition: 0.3s;
      }
      .text-active {
        color: #FFBE0B;
        transition: 0.3s;
        font-weight: 600;
      }
    }
  }
}

.results-container {
  width: 85%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  div {
    text-align: center;
    scroll-snap-align: start;
  }
  .loading-container {
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1100px) {
  .navigation {
    gap: 6px !important;
    .line-inactive {
      width: 3px !important;

    }
    .line-active {
      width: 6px !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .results-container {
    width: 95% !important;
  }
  .side-bar-container {
    width: 5%;
    .side-bar-logo {
      display: none;
    }
  }
  .text-active,
  .text-inactive {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .side-bar-container {
    width: 6px;
  }
  .dot-active,
  .dot-inactive {
    display: none;
  }
  .results-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 380px) {
  .side-bar-container {
    display: none;
  }
}
</style>