<template>
  <v-container class="d-flex align-center justify-center text-center flex-column mt-5">
    <h1>Login</h1>
    <v-card class="mx-auto p-4" width="400">
      <v-card-text>
        <v-form  @submit.prevent="login" ref="form" lazy-validation class="m-2">
          <v-text-field
              v-model="form.username"
              label="Username"
              type="text"
              :rules="rules"
          ></v-text-field>

          <v-text-field
              v-model="form.password"
              label="Password"
              type="password"
              :rules="rules"
          ></v-text-field>

          <v-btn class="mt-2" type="submit" block>Submit</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import config from "@/helpers/api";
export default {
  name: 'LoginView',
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      rules: [
        (value) => {
          if (value) return true;
          return "Field is required";
        },
      ],
    };
  },
  methods: {
    async login() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      await fetch(`${config.baseURL}/login`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify(this.form),
      }).then(async (response) => {
        const responseBody = await response.json();
        if (!response.ok) {
          alert("Failed to login");
        } else {
          const token = responseBody.access_token;
          localStorage.removeItem("token");
          localStorage.setItem("token", token);
          config.headers = new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });
          config.formDataHeaders = new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          });
          setTimeout(() => {
            this.$router.push("/admin");
          }, 1000);
        }
      });
    },
  },
}
</script>