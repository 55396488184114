<template>
  <div class="container">
    <div class="landing-container">
      <h1 class="landing-title text-center">{{ $store.getters.langData.home.title }}</h1>
      <h3 class="landing-subtitle">{{ $store.getters.langData.home.descriptionTitle }}</h3>
      <div class="description-container">

        <p class="lading-description">{{ $store.getters.langData.home.largeText1 }}</p>
        <p class="lading-description"><br>{{ $store.getters.langData.home.largeText2 }}</p>
        <p class="lading-description"><br>{{ $store.getters.langData.home.largeText3 }}</p>
      </div>
      <MainButton class="main-button" @click="$router.push('/test')">{{ $store.getters.langData.home.startTest }}</MainButton>
    </div>
  </div>
</template>

<script>

import MainButton from "@/components/MainButton.vue";

export default {
  name: 'HomeView',
  components: {MainButton},
  data() {
    return {
      landingImage: new URL("/src/assets/sausmas.png", import.meta.url).href,
    }
  }
}
</script>

<style scoped>
.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-inline: auto;
  width: 50%;
}

.landing-title {
  font-family: "Roboto Black", sans-serif;
  font-weight: 900;
  font-size: 4rem;
  line-height: 4rem;
}

.landing-subtitle {
  font-family: "Roboto Bold", sans-serif;
  font-size: 24px;
  line-height: 24px;
}

.description-container {
  width: 70%;
}

.lading-description {
  font-family: "Roboto Light", sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.landing-image {
  width: 100%;
  display: inline-block;
  z-index: 1;
  margin-top: 40px;
}

h1 {
  font-weight: 800;
  font-size: 4rem;
  line-height: 4rem;
}

.image-container {
  border-radius: 20px;
  border: 2px solid #FFBC00;
  -webkit-box-shadow: -20px -20px 0px 0px rgba(255,190,11,0.3);
  -moz-box-shadow: -20px -20px 0px 0px rgba(255,190,11,0.3);
  box-shadow: -20px -20px 0px 0px rgba(255,190,11,0.3);
  overflow: hidden;
}

@media only screen and (max-width: 1260px) {
  .landing-image {
    display: none;
  }

  .landing-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  .landing-content {
    align-items: center;
    max-width: 100vw;
    margin: 0;
  }
}
</style>