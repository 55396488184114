<template>
  <div :id="id" class="section">
    <div class="container">
      <div class="section-left">
        <img v-if="isFirst" class="logo" src="../assets/logo.svg" alt="logo">
        <img class="speciality-image" :src="speciality.banner_url">
        <div class="apple-container desktop-container">
          <p class="statistics-description">{{ $store.getters.langData.result.keywordsText }}</p>
          <div class="statistics-container">
            <div v-if="percentages[0]" class="percentage-container">
              <v-progress-circular
                :model-value="percentages[0].value"
                :size="100"
                :width="9"
                color="#FFBE0B"
              >
                <p class="progress-bar-text">{{ percentages[0].value }}%</p>
              </v-progress-circular>
              <p class="progress-bar-text">{{ speciality.keywords[0].text }}</p>
            </div>
            <div v-if="percentages[1]" class="percentage-container">
              <v-progress-circular
                :model-value="percentages[1].value"
                :size="100"
                :width="9"
                color="#FFBE0B"
              >
                <p class="progress-bar-text">{{ percentages[1].value }}%</p>
              </v-progress-circular>
              <p class="progress-bar-text">{{ speciality.keywords[1].text }}</p>
            </div>
            <div v-if="percentages[2]" class="percentage-container">
              <v-progress-circular
                :model-value="percentages[2].value"
                :size="100"
                :width="9"
                color="#FFBE0B"
              >
                <p class="progress-bar-text">{{ percentages[2].value }}%</p>
              </v-progress-circular>
              <p class="progress-bar-text">{{ speciality.keywords[2].text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="section-right">
        <div class="speciality">
          <p class="speciality-order">{{ language }}</p>
          <h1 class="speciality-title">{{ speciality.name }}</h1>
<!--          <div class="speciality-hashtags">-->
<!--            <HashtagComponent v-for="(hashtag, index) in speciality.hashtags" :key="index" :hashtag="hashtag"></HashtagComponent>-->
<!--          </div>-->
          <div v-html="speciality.text"></div>
<!--          <p class="speciality-description">{{ speciality.description }}</p>-->
        </div>
        <div class="school">
          <h1 class="school-title">{{ $store.getters.langData.result.SchoolTitle }}</h1>
          <div v-html="speciality.school_description"></div>
        </div>
        <div v-if="speciality" class="apple-container mobile-container">
          <p class="statistics-description">{{ $store.getters.langData.result.keywordsText }}</p>
          <div class="statistics-container">
            <div v-if="percentages[0]" class="percentage-container">
              <v-progress-circular
                :model-value="percentages[0].value"
                :size="100"
                :width="9"
                color="#FFBE0B"
              >
                <p class="progress-bar-text">{{ percentages[0].value }}%</p>
              </v-progress-circular>
              <p class="progress-bar-text">{{ speciality.keywords[0].text }}</p>
            </div>
            <div v-if="percentages[1]" class="percentage-container">
              <v-progress-circular
                :model-value="percentages[1].value"
                :size="100"
                :width="9"
                color="#FFBE0B"
              >
                <p class="progress-bar-text">{{ percentages[1].value }}%</p>
              </v-progress-circular>
              <p class="progress-bar-text">{{ speciality.keywords[1].text }}</p>
            </div>
            <div v-if="percentages[2]" class="percentage-container">
              <v-progress-circular
                :model-value="percentages[2].value"
                :size="100"
                :width="9"
                color="#FFBE0B"
              >
                <p class="progress-bar-text">{{ percentages[2].value }}%</p>
              </v-progress-circular>
              <p class="progress-bar-text">{{ speciality.keywords[2].text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SpecialityView',
  props: {
    id: {
      type: String,
    },
    speciality: {
      type: Object
    },
    language: {
      type: String,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChangedValue: false,
      percentages: []
    }
  },
  mounted() {
    this.speciality.keywords.forEach(() => {
      this.percentages.push({
        value: 0
      })
    })
  },
  methods: {
    changeStatisticValues() {
      if (this.isChangedValue) return
      this.isChangedValue = true
      this.percentages.forEach((percentage, index) => {
        percentage.value = this.speciality.keywords[index].percentage_answered;
      })
    }
  }
}
</script>

<style scoped>
.section {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  gap: 25px;
}

.section-left {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 40%;
  .logo {
    display: none;
    margin-inline: auto;
    width: 50%;
  }
  .speciality-image {
    width: 100%;
    object-fit: contain;
    height: 425px;
  }
}

.apple-container {
  height: fit-content !important;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  border-radius: 18px;
  border: 1px solid #f7f7f7;
}

.desktop-container {
  display: block;
}
.mobile-container {
  display: none;
}

.statistics-description {
  padding: 32px 32px;
  text-align: start;
  font-size: 18px;
  color: #4E4E4E;
}

.statistics-container {
  padding-bottom: 32px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 18px;
  .percentage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    font-size: 24px;
    color: #4E4E4E;
    gap: 12px;
    .progress-bar-text {
      text-align: center;
      font-size: 20px;
      color: #4E4E4E;
      user-select: none;
    }
  }
}

.section-right {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 45%;
  text-align: left;
  .speciality {
    .speciality-order {
      font-size: 24px;
      color: #4E4E4E;
    }
    .speciality-title {
      font-size: 43px;
      color: #FFBE0B;
    }
    .speciality-hashtags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      margin-bottom: 10px;
      gap: 7px;
    }
    .speciality-description {
      font-size: 16px;
      color: #4E4E4E;
    }
  }
  .school {
    .school-title {
      font-size: 43px;
      color: #FFBE0B;
    }
    .school-description {
      font-size: 16px;
      color: #4E4E4E;
    }
  }
}

@media only screen and (max-width: 900px) {
  .desktop-container {
    display: none;
  }
  .mobile-container {
    display: block;
  }
  .container {
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 200px;
  }
  .section-left {
    width: 80%;
    .logo {
      width: 35%;
      display: block;
    }
  }
  .statistics-container {
    flex-direction: row;
  }
  .section-right {
    width: 80%;
  }
}

@media only screen and (max-width: 380px) {
  .progress-bar-text {
    font-size: 18px !important;
  }
  .section-left,
  .section-right {
    width: 90%;
    .speciality {
      .speciality-order {
        font-size: 18px;
      }
      .speciality-title {
        font-size: 32px;
      }
    }
    .school {
      .school-title {
        font-size: 32px;
      }
    }
  }
}
</style>