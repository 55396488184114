<template>
  <v-form ref="form" class="w-100 d-flex flex-column mt-5" @submit.prevent>
    <!--  Success message  -->
    <v-alert
      v-show="success"
      type="success"
      class="mb-5"
      text="Successfully edited row"
    ></v-alert>

    <!--  Failure message  -->
    <v-alert
        v-show="failure"
        type="error"
        class="mb-5"
    >
      <h2>{{ error }}</h2>
      <ul>
        <li v-for="(listError, index) in errors" :key="index">{{ listError[0] }}</li>
      </ul>
    </v-alert>

    <!--  Form content  -->
    <component
        :is="field.component"
        v-for="(field, index) in fields"
        :key="index"
        :ref="field.data.name"
        v-model="field.data.value"
        :data="field.data"
        class="component"
    >
    </component>

    <!-- Submit button -->
    <v-btn
      type="submit"
      block
      class="mt-5"
      text="Submit"
      @click="submit"
    ></v-btn>
  </v-form>
</template>

<script>
import config from "@/helpers/api";

export default {
  props: {
    fields: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      failure: false,
      errors: [],
      error: null,
    };
  },
  methods: {
    async submit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      const formData = new FormData();
      for (const key in this.fields) {
        const field = this.$refs[this.fields[key].data.name][0];
        if (field.value === null || field.value === undefined) continue;
        if (typeof field.value === "object") {
          if (field.value[1]) {
            for (const fieldIndex in field.value) {
              if (field.value[1].id) {
                formData.append(
                  `${field.data.name}[${fieldIndex}]`,
                  field.value[fieldIndex].id
                );
              } else {
                formData.append(
                  `${field.data.name}[${fieldIndex}]`,
                  field.value[fieldIndex]
                );
              }
            }
          } else if (field.value[0]) {
            if (field.data.name === "keywords") {
              formData.append(`${field.data.name}[0]`, field.value[0].id);
            } else {
              formData.append(field.data.name, field.value[0]);
            }
          } else if (field.value.id) {
            formData.append(field.data.name, field.value.id);
          } else {
            formData.append(field.data.name, field.value);
          }
        } else {
          formData.append(field.data.name, field.value);
        }
      }
      await fetch(
        `${config.baseURL}/${this.name}/${this.$route.params.id}?_method=PUT`,
        {
          method: "POST",
          headers: config.formDataHeaders,
          body: formData,
        }
      )
        .then(async (response) => {
          document.querySelector('.content-wrapper').scrollTo({ top: 0, left: 0, behavior: "smooth" })
          if (response.ok) {
            this.failure = false;
            this.success = true;
            setTimeout(() => {
              this.$router.push(`/admin/${this.name}/list`);
            }, 1000);
          } else {
            const data = await response.json()
            this.failure = true;
            this.error = data.message ?? 'There is a error'
            this.errors = data.errors ?? null
          }
        }).finally(() => {
            this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
form {
  gap: 5px;
  padding: 10px;
}
</style>
