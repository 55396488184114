<template>
  <div class="container-wrapper">
    <div class="container">
      <div class="control-container">
        <div class="title-container">
          <p>{{ title }}</p>
        </div>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppleContainer",
  props: ['title'],
  data() {
    return {
      width: 100,
      height: 100,
    }
  }
}
</script>

<style scoped>
.container, .container-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 18px;
}

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid #f7f7f7;
  -webkit-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  -moz-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
  overflow: hidden;
}

.control-container {
  background-color: #fafafa;
  -webkit-box-shadow: 0 0.5px 1px 0 rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0.5px 1px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0.5px 1px 0 rgba(0,0,0,0.1);
  height: 38px;
  display: flex;
  align-items: center;
}

.controls > div {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.controls > div:nth-child(1) {
  background-color: #EC6A5E;
}

.controls > div:nth-child(2) {
  background-color: #F4BE4F;
}

.controls > div:nth-child(3) {
  background-color: #60C553;
}

.title-container {
  width: 100%;
  text-align: center;
}

.content {
  width: 100%;
  height: 100%;
}
</style>