<template>
  <v-app id="app">
    <component :is="$route.meta.layout">
      <RouterView />
    </component>
  </v-app>
</template>

<script>
import config from "@/helpers/api";

export default {
  data () {
    return {
      defaultLanguage: process.env.VUE_APP_LANGUAGE_CODE,
    }
  },
  beforeCreate() {
    fetch(`${config.baseURL}/current_language`, {
      method: "GET",
      headers: config.headers,
    })
        .then((response) => response.json())
        .then((response) => {
          document.title = response.home.tabTitle;
          this.$store.dispatch("editLangData", response);
          localStorage.setItem("language", JSON.stringify(response));
          this.$forceUpdate();
        });
  },
}
</script>


<style>
body { position: static; top: 0px !important }
iframe.skiptranslate { display: none !important; }

</style>