<template>
  <div class="progress-bar" ref="progress"></div>
</template>


<script>
export default {
  name: 'ProgressBar',
  methods: {
    setProgress(value) {
      this.$refs.progress.style.width = `${value}%`
    },
  }
}
</script>

<style scoped>
.progress-bar {
  height: 10px;
  background-color: #FFBE0B;
  position: absolute;
  top: 0;
  transition: .4s all;
  left: 0;
}
</style>
