<template>
  <v-main>
    <slot />
  </v-main>
</template>

<script>
export default {
  mounted() {
    this.loadGoogleTranslateScript()
  },
  methods: {
    loadGoogleTranslateScript() {
      const existingScript = document.getElementById('google-language');
      if (existingScript) {
        existingScript.remove(); // Remove any existing script to ensure a clean load
      }

      const script = document.createElement('script')
      script.id = 'google-language'
      script.src =
          '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      script.async = true
      document.head.appendChild(script)
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
              pageLanguage: process.env.VUE_APP_LANGUAGE_CODE,
              includedLanguages: `${process.env.VUE_APP_LANGUAGE_CODE},en`,
              layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT
            },
            'app'
        )
      }
    },
  }
}
</script>