const config = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    formDataHeaders: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    // token: localStorage.getItem("token") || null,
};
export default config;