import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from "@/layouts/MainLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ResultView from '../views/ResultsView.vue'
import TestView from '@/views/TestView.vue';
import LoadingView from '@/views/LoadingView.vue';

// Admin
import MainView from "@/Admin/views/MainView.vue";
import AdminLayout from "@/Admin/layouts/AdminLayout.vue";

// Admin Keywords
import KeywordListView from "@/Admin/views/keywords/ListView.vue";
import KeywordCreateView from "@/Admin/views/keywords/CreateView.vue";
import KeywordEditView from "@/Admin/views/keywords/EditView.vue";

// Admin tests
import TestListView from "@/Admin/views/tests/ListView.vue";
import TestEditView from "@/Admin/views/tests/EditView.vue";
import TestCreateView from "@/Admin/views/tests/CreateView.vue";

// Admin departments
import DepartmentListView from "@/Admin/views/departments/ListView.vue";
import DepartmentCreateView from "@/Admin/views/departments/CreateView.vue";
import DepartmentEditView from "@/Admin/views/departments/EditView.vue";

// Admin specialities
import SpecialityListView from "@/Admin/views/specialities/ListView.vue";
import SpecialityCreateView from "@/Admin/views/specialities/CreateView.vue";
import SpecialityEditView from "@/Admin/views/specialities/EditView.vue";

// Admin language
import LanguageListView from "@/Admin/views/language/ListView.vue";
import LanguageEditView from "@/Admin/views/language/EditView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: MainLayout,
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: MainLayout,
    },
    component: LoginView
  },
  {
    path: '/results/:key',
    name: 'results',
    meta: {
      layout: DefaultLayout,
    },
    component: ResultView
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      layout: TestView,
    },
  },
  {
    path: '/loading/:key',
    name: 'loading',
    meta: {
      layout: LoadingView,
    },
  },
    // Admin
  {
    path: "/admin",
    name: "admin",
    meta: {
      layout: AdminLayout,
    },
    component: MainView,
  },
  {
    path: "/admin/keywords/list",
    name: "admin-keywords-list-view",
    meta: {
      layout: AdminLayout,
    },
    component: KeywordListView,
  },
  {
    path: "/admin/keywords/create",
    name: "admin-keywords-create-view",
    meta: {
      layout: AdminLayout,
    },
    component: KeywordCreateView,
  },
  {
    path: "/admin/keywords/edit/:id",
    name: "admin-keywords-edit-view",
    meta: {
      layout: AdminLayout,
    },
    component: KeywordEditView,
  },
  {
    path: "/admin/tests/list",
    name: "admin-tests-list-view",
    meta: {
      layout: AdminLayout,
    },
    component: TestListView,
  },
  {
    path: "/admin/tests/create",
    name: "admin-tests-create-view",
    meta: {
      layout: AdminLayout,
    },
    component: TestCreateView,
  },
  {
    path: "/admin/tests/edit/:id",
    name: "admin-tests-edit-view",
    meta: {
      layout: AdminLayout,
    },
    component: TestEditView,
  },
  {
    path: "/admin/departments/list",
    name: "admin-departments-list-view",
    meta: {
      layout: AdminLayout,
    },
    component: DepartmentListView,
  },
  {
    path: "/admin/departments/create",
    name: "admin-departments-create-view",
    meta: {
      layout: AdminLayout,
    },
    component: DepartmentCreateView,
  },
  {
    path: "/admin/departments/edit/:id",
    name: "admin-departments-edit-view",
    meta: {
      layout: AdminLayout,
    },
    component: DepartmentEditView,
  },
  {
    path: "/admin/specialities/list",
    name: "admin-specialities-list-view",
    meta: {
      layout: AdminLayout,
    },
    component: SpecialityListView,
  },
  {
    path: "/admin/specialities/create",
    name: "admin-specialities-create-view",
    meta: {
      layout: AdminLayout,
    },
    component: SpecialityCreateView,
  },
  {
    path: "/admin/specialities/edit/:id",
    name: "admin-specialities-edit-view",
    meta: {
      layout: AdminLayout,
    },
    component: SpecialityEditView,
  },
  {
    path: "/admin/language/list",
    name: "admin-language-list-view",
    meta: {
      layout: AdminLayout,
    },
    component: LanguageListView,
  },
  {
    path: "/admin/language/edit/:id",
    name: "admin-language-edit-view",
    meta: {
      layout: AdminLayout,
    },
    component: LanguageEditView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, _from, next) => {
  if (to.meta.layout && to.meta.layout.name === "AdminLayout") {
    if (localStorage.getItem("token")) {
      await import("../Admin/assets/style/style.css");
      next();
      return 0;
    }
    next("/login");
  }
  await import("../assets/main.css");
  next();
});


export default router
