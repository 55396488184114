<template>
  <footer>
    <v-divider />
    <div class="d-flex flex-column justify-center">
      <div class="justify-center align-center desktop partner-list">
        <v-img :src="partner1" width="60px" class="logo" :class="{'order-0': currentLanguage === 'lv', 'order-1':  currentLanguage !== 'lv'}"/>
        <v-img :src="partner2" width="60px" class="logo" :class="{'order-0': currentLanguage === 'est', 'order-2':  currentLanguage !== 'et'}"/>
        <v-img :src="partner3" width="60px" class="logo" :class="{'order-0': currentLanguage === 'mk', 'order-3':  currentLanguage !== 'mk'}"/>
        <v-img :src="erasmus" width="210px" class="logo order-4"/>
      </div>
      <p class="ma-2">© CareerChoice 2024</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      partner1: new URL("/src/assets/rvt.png", import.meta.url).href,
      partner2: new URL("/src/assets/voco.png", import.meta.url).href,
      partner3: new URL("/src/assets/parentija.png", import.meta.url).href,
      erasmus: new URL("/src/assets/erasmus.png", import.meta.url).href,
    }
  }
}
</script>

<style scoped>
footer {
  width: 100%;
  text-align: center;
  color: #a9a9a9;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.partner-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr;
  gap: 5vw;
  max-width: 70vw;
  margin: auto;
  padding: 10px 0 5px 0;
}

@media only screen and (min-width: 1460px) {
  .desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 1460px) {
  .desktop {
    display: flex !important;
  }
}

@media only screen and (max-width: 790px) {
  .desktop {
    flex-wrap: wrap;
  }
}

</style>