import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/main.css'
import '@/styles/main.scss'
import { createStore } from "vuex";
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
    },
    components,
    directives,
});

const store = createStore({
    state() {
        return {
            langData: JSON.parse(localStorage.getItem("language")) || {},
        };
    },
    getters: {
        langData(state) {
            return state.langData;
        },
    },
    mutations: {
        updateLangData(state, newData) {
            state.langData = newData;
        },
    },
    actions: {
        editLangData(state, newData) {
            store.commit("updateLangData", newData);
        },
    },
});

createApp(App).use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITEKEY }).use(router).use(store).use(vuetify).mount('#app')
