<template>
  <ProgressBar ref="progress-bar"/>
  <main class="d-flex align-center flex-column">
    <img src="@/assets/logo.svg" width="146">
    <h1 class="mt-15">{{ $store.getters.langData.loading.loadingTitle }}</h1>
    <h2>{{ $store.getters.langData.loading.loadingSubtitle }}</h2>
    <div class="d-flex align-center justify-center flex-column mt-15">
      <span>{{ progress }}%</span>
      <div class="progress-bar-container">
        <div :style="{width: progress + '%'}" class="progress"></div>
      </div>

      <v-btn class="mt-10" v-if="progress === 100" @click="redirect()">{{ $store.getters.langData.loading.viewResults }}</v-btn>
    </div>
  </main>
</template>

<script>
import ProgressBar from "@/components/ProgressBar.vue";
import JSConfetti from 'js-confetti'

export default {
  name: 'LoadingView',
  components: {ProgressBar},
  data () {
    return {
      progress: 0
    }
  },
  mounted() {
    // Set confetti
    const jsConfetti = new JSConfetti()
    jsConfetti.addConfetti({
      confettiRadius: 6,
      confettiNumber: 100,
    })

    // Set progress bar to 100
    this.$refs['progress-bar'].setProgress(100)

    // Loading progress
    setTimeout(() => {
      this.progress = 6
      setTimeout(() => {
        this.progress = 32
        setTimeout(() => {
          this.progress = 60
          setTimeout(() => {
            this.progress = 89
            setTimeout(() => {
              this.progress = 92
              setTimeout(() => {
                this.progress = 100
              }, 550)
            }, 350)
          }, 950)
        }, 850)
      }, 1000)
    }, 500)
  },
  methods: {
    redirect: function () {
      this.$router.push('/results/' + this.$route.params.key)
    }
  }
}
</script>


<style scoped>
h1 {
  font-weight: 600;
  text-align: center;
}

h2 {
  font-weight: lighter;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

main {
  height: 100vh;
  padding-top: 100px;
}

.progress-bar-container {
  appearance: none;
  width: 275px;
  height: 14px;
  background: #FFFFFF;
  border: 1px solid #ececec;
  border-radius: 12px;
  position: relative;
}

.progress {
  position: absolute;
  height: 13px;
  background: #FFBE0B;
  border-radius: 12px;
  transition: .2s all;
}

span {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}

button {
  padding: 10px;
  background-color: #FFBE0B;
  border-radius: 6px;
  gap: 20px;
  color: white;
  font-weight: bold;
}
</style>