<template>
  <v-btn
    append-icon="mdi-arrow-right"
    variant="flat"
    rounded="xl"
    color="#FFBE0B"
    class="d-flex justify-space-between text-none"
  >
    <b><slot></slot></b>
  </v-btn>
</template>

<script>
export default {
  name: "MainButton",
}
</script>

<style scoped>

</style>